@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap);
body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #32296d;
  min-height: 100vh;
  justify-content: space-between;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
  margin: 10px 0;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 1;
}

.App-header-text {
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.App-header-text h2 {
  font-weight: 500;
  font-size: 20px;
}
.App-header-text p {
  font-weight: 400;
  font-size: 17px;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 20px 0;
}

.App-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
.App-desktop .buttons {
  display: flex;
  flex-direction: row;
}

.text-download-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 10px 0;
}
.text-download-container .text-download {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

hr {
  margin-left: 0;
  margin-right: 0;
  flex: 0.5 1;
  border: 1px solid #9894b5;
}

.button {
  border-radius: 8px;
  margin: 10px 0;
  width: 40%;
  color: #32296d;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.button-see {
  background-color: #ffffff;
  border: 1px white solid;
}

.button-go {
  background-color: #eb661c;
}
.links {
  -webkit-padding-before: 1em;
          padding-block-start: 1em;
  -webkit-padding-after: 1em;
          padding-block-end: 1em;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-padding-end: 0px;
          padding-inline-end: 0px;
  color: #32296d;
  text-decoration: none;
}

.logo-store {
  height: 61px;
  margin: 0 20px;
}

@supports (-webkit-touch-callout: none) {
  .App {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

